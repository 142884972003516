<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->

  <q-dialog v-model="this.noteModalVisible" transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <q-form
            class="padding-sm"
            @submit="submitNote"
            @reset="closeNoteModal">
          <q-item>
            <q-input
                filled
                v-model="this.note"
                label="Note"
                type="textarea"
                lazy-rules
                :rules="[val => val.length > 0 || 'Please enter a note']"
            ></q-input>
          </q-item>
        </q-form>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Cancel" @click="closeNoteModal()" type="submit" color="secondary"></q-btn>
        <q-btn label="Done" @click="submitNote()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">
        <div :key="render_table">
          <div class="q-mt-md q-mb-md">
            <q-btn @click="goBack()" color="black" class="text-white">
              <q-icon name="arrow_back" size="xs"></q-icon> &nbsp;Back
            </q-btn>
          </div>
          <div v-if="this.tab===1">
              <TaskBasic @reload="syncData();" :returned_tasks="this.returned_tasks"></TaskBasic>
          </div>
          <div v-if="this.tab===3">
            <TaskNotes :reload="this.reload_notes" @addNote="showNoteModal()" :returned_tasks="this.returned_tasks"></TaskNotes>
          </div>
          <div v-if="this.tab===4">
            <TaskAttachments @camera_open="this.camera_open = true" @camera_close="this.camera_open = false" :returned_tasks="this.returned_tasks"></TaskAttachments>
          </div>
            <q-footer v-if="!this.camera_open">
              <q-toolbar>
                <q-btn class="bg-grey-8" @click="changeTab(1)">
                  Task
                </q-btn>
                <q-btn class="bg-green-5" @click="changeTab(3)">
                  Notes
                </q-btn>
                <q-btn class="bg-purple-5"  @click="changeTab(4)">
                  Attachments
                </q-btn>
              </q-toolbar>
            </q-footer>
        </div>
    </div>
  </div>

</template>


<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
import TaskBasic from "@/views/Task/TaskBasic.vue";
import TaskAttachments from "@/views/Task/TaskAttachments.vue";
import {Notify} from "quasar";
import TaskNotes from "@/views/Task/TaskNotes.vue";
export default {
  name: 'OpenTasksPage',
  components:{
    TaskBasic,
    TaskAttachments,
    TaskNotes
  },
  setup(){
    return {
    }
  },
  data(){
    return {
      camera_open : false,
      noteModalVisible : false,
      returned_tasks : [],
      render_table : null,
      current_user : null,
      reload_notes : 0,
      tab : 1,
      note: null,
      users : null

    }
  },
  mounted(){
    this.syncData();
  },
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  methods : {
    changeTab(tab){
      this.tab = tab;
    },
    goBack(){
      this.$router.go('-1');
    },
    submitNote(){
      let user_id =this.user_id;
      let task_id = this.$route.params.id;
      let note = this.note;
      axios.post(this.$store.getters.url+'/api/cases/task-notes',{
        user_id: user_id,
        task_id: task_id,
        note: note
      })
      .then(() => {
        Notify.create({
          progress: true,
          timeout: 1000,
          position: 'center',
          color: 'green-4',
          textColor: 'white',
          icon: 'cloud_done',
          message: 'Note Added !! '
        })
        this.reload_notes = 1;
        this.changeTab(3);
      })
      .catch(() => {
      }).finally(() => {
        this.note = null;
        this.syncData();
      });

      this.noteModalVisible = false;
    },
    closeNoteModal(){
      this.noteModalVisible = false;
    },
    showNoteModal(){
      this.noteModalVisible = true;
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users')
          .then((res) => {
            this.users = res.data.data;
            let user_id =this.user_id;
            this.users.forEach((user) => {
              if(user.id === user_id){
                this.current_user = user.name;
              }
            });
            this.returned_tasks.forEach((returned_Task,index) =>{
              if(returned_Task.allocated_by !== this.current_user){
                this.returned_tasks.splice(index);
              }
            });
          })
          .catch(() => {
          }).finally(() => {
          });
    },
    async syncData(){
      const router = useRouter()
      let token = this.$store.getters.token;
      if( token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }else{
        router.push({ name: "login"})
      }
        axios.get(this.$store.getters.url+'/api/api-tasks/'+this.$route.params.id)
            .then((res) => {
              // eslint-disable-next-line no-unreachable
              Notify.create({
                progress: true,
                timeout: 1000,
                position: 'center',
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Tasks Retrieved !! '
              })
              this.returned_task = res.data.task;
              this.getUsers();
              this.render_table = true;
            })
            .catch((error) => {
              console.log(error)
              Notify.create({
                progress: true,
                timeout: 1000,
                position: 'center',
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Error retrieving tasks: '+error
              })
            }).finally(() => {
          //Perform action in always
        });
    },
  }
}
</script>
