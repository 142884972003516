<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar v-if="this.token">
        <q-btn
            flat
            dense
            round
            @click="leftDrawerOpen = !leftDrawerOpen"
            aria-label="Menu"
            icon="menu"
        />

        <q-toolbar-title>
          {{ this.app_name }}
        </q-toolbar-title>
        <q-item-section avatar>
          <q-btn  color="red" icon="emergency" @click="displayPanic()" label="Panic" stack></q-btn>
          <q-spinner-gears v-if="this.loading" color="black"></q-spinner-gears>
        </q-item-section>
        <q-item-section avatar>
          <q-btn  color="secondary" icon="report" @click="displayReport()" label="Report" stack>
            <q-spinner-gears v-if="this.loading" color="black"></q-spinner-gears>
          </q-btn>
        </q-item-section>
        <div>2.11</div>
      </q-toolbar>
    </q-header>
    <q-drawer
        v-model="leftDrawerOpen"
        show-if-above
        bordered
        class="bg-grey-2"
    >

      <q-dialog v-model="this.displayPanicModal" transition-show="rotate" transition-hide="rotate">
        <q-card class="text-black flex justify-center items-center" style="width: 100%;height: 100%">
          <q-card-section class="q-pt-none">
            <RadialMenu @clickedEvent="submitPanic($event)" :items="this.panic_sub_categories" :radius="100">
              <template #center>
                <q-btn class="circle" round>
                  Panic
                </q-btn>
              </template>
              <template v-for="(item, index) in this.panic_sub_categories" :key="index">
              </template>
            </RadialMenu>
            <!--            <q-form-->
            <!--                method="post" @submit="this.submitPanic()"-->
            <!--                class="q-gutter-md">-->
            <!--              <div>-->
            <!--                <q-card-section>-->
            <!--                  <div class="text-h6">Panic</div>-->
            <!--                </q-card-section>-->
            <!--                <q-card-section style="margin-top: 10px">-->
            <!--                  <q-select v-model="this.panic_sub_category"-->
            <!--                            :options="this.panic_sub_categories"-->
            <!--                            emit-value-->
            <!--                            option-label="description"-->
            <!--                            :label="'Panic Sub Category'">-->
            <!--                  </q-select>-->
            <!--                </q-card-section>-->
            <!--                <q-input style="margin-top: 20px" placeholder="Enter case description" type="text" v-model="this.panic_description">-->
            <!--                </q-input>-->
            <!--                <q-btn @click="this.takePhoto(this.panicPhotos)" color="primary" round  icon="photo_camera"></q-btn>-->
            <!--                <div v-for="(panicPhoto,index) in this.panicPhotos" :key="index">-->
            <!--                  <q-img-->
            <!--                      :key="panicPhoto"-->
            <!--                      :src="panicPhoto"-->
            <!--                      style="height: 140px; max-width: 150px">-->
            <!--                  </q-img>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </q-form>-->
          </q-card-section>
        </q-card>
      </q-dialog>
      <q-dialog v-model="this.displayReportModal" transition-show="rotate" transition-hide="rotate">
        <q-card class="bg-teal text-white" style="width: 100%">
          <q-card-section class="q-pt-none">
            <q-form
                method="post" @submit="this.submitIssue()"
                class="q-gutter-md">
              <div>
                <q-card-section>
                  <div class="text-h6">Report Issue</div>
                </q-card-section>
                <q-card-section style="margin-top: 10px">
                  <q-select v-model="this.selected_category"
                            :options="this.docket_categories"
                            emit-value
                            :label="this.docket_description+' Category'"
                            option-label="name"
                  >

                  </q-select>
                </q-card-section>
                <q-card-section style="margin-top: 10px">
                  <q-select v-model="this.selected_sub_category"
                            :options="this.docket_sub_categories"
                            emit-value
                            option-label="description"
                            :label="this.docket_description+' Sub Category'">
                  </q-select>
                </q-card-section>

                <q-input style="margin-top: 20px" placeholder="Enter case description" type="text" v-model="this.description">

                </q-input>
                <q-btn @click="this.takePhoto(this.casePhotos)" color="primary" round  icon="photo_camera"></q-btn>
                <div v-for="(panicPhoto,index) in this.casePhotos" :key="index">
                  <q-img
                      :key="panicPhoto"
                      :src="panicPhoto"
                      style="height: 140px; max-width: 150px">
                  </q-img>
                </div>
              </div>
            </q-form>
          </q-card-section>

          <q-card-actions align="right" class="bg-white text-teal">
            <q-btn label="Cancel" @click="closeReportModal()" type="submit" color="secondary"></q-btn>
            <q-btn label="Done" @click="submitIssue()" type="submit" color="primary"></q-btn>
          </q-card-actions>

        </q-card>
      </q-dialog>

      <q-list>
        <q-item-label header>Menu</q-item-label>
        <q-item to="/" exact>
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Home</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="this.token" to="/my-cases" exact>
          <q-item-section avatar>
            <q-icon name="work" />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              My {{this.docket_description}}s  <span class="float-right">( {{ this.docket_counter }} ) </span>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="this.token" to="/open-tasks" exact>
          <q-item-section avatar>
            <q-icon name="list" />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              Open Tasks <span class="float-right">( {{ this.task_counter }} ) </span>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="this.token" to="/closed-tasks" exact>
          <q-item-section avatar>
            <q-icon name="task_alt" />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              Closed Tasks
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="this.token" to="/inbox" exact>
          <q-item-section avatar>
            <q-icon name="message" />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              Inbox <span class="float-right">( {{ this.unread_message_counter }} )</span>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="this.token" to="/compose-message/0" exact>
          <q-item-section avatar>
            <q-icon name="edit_note" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Compose new message</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="this.token" to="/my-reports" exact>
          <q-item-section avatar>
            <q-icon name="person_alert" />
          </q-item-section>
          <q-item-section>
            <q-item-label>My Reports</q-item-label>
          </q-item-section>
        </q-item>
        <q-item v-if="this.token" to="/logout" exact>
          <q-item-section avatar>
            <q-icon name="logout" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Log Out</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>

    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import {Notify} from "quasar";
import NoSleep from "nosleep.js";
import {Camera, CameraResultType} from "@capacitor/camera";
import RadialMenu from "@/components/RadialMenu.vue";
export default {
  name: 'LayoutDefault',
  components:{
    RadialMenu
  },
  data(){
    return {
      displayPanicModal : false,
      displayReportModal : false,
      loading : false,
      description: '',
      selected_category : null,
      selected_sub_category : null,
      casePhotos : [],
      panic_category : null,
      panic_sub_category : null,
      panic_description : null,
      panic_sub_categories : null,
      panicPhotos : [],
    }
  },
  mounted(){
    this.setupPage();
    let app = this;
    setInterval(function(){
      app.checkAndGetLocation();
    }, 30000);

  },
  computed: {
    app_name(){
      return process.env.VUE_APP_NAME;
    },
    unread_message_counter(){
      return this.$store.getters.counter;
    },
    docket_counter(){
      return this.$store.getters.docket_counter;
    },
    task_counter(){
      return this.$store.getters.task_counter;
    },
    url(){
      return this.$store.getters.url;
    },
    level_one_in_use(){
      return this.$store.getters.level_one_in_use;
    },
    level_two_in_use(){
      return this.$store.getters.level_two_in_use;
    },
    level_three_in_use(){
      return this.$store.getters.level_three_in_use;
    },
    level_ones(){
      return this.$store.getters.level_ones;
    },
    level_twos(){
      return this.$store.getters.level_twos;
    },
    level_threes(){
      return this.$store.getters.level_threes;
    },
    level_fours(){
      return this.$store.getters.level_fours;
    },
    level_fives(){
      return this.$store.getters.level_fives;
    },
    docket_description(){
      return this.$store.getters.docket_description;
    },
    title() {
      return process.env.VUE_APP_NAME;
    },
    token() {
      return this.$store.getters.token;
    },
    docket_categories(){
      return this.$store.getters.docket_categories;
    },
    docket_sub_categories(){
      return this.$store.getters.docket_sub_categories;
    },
    user_id(){
      return this.$store.getters.user_id;
    },
    five_description(){
      return this.$store.getters.five_description;
    },
    current_level_five(){
      return this.$store.getters.current_level_five;
    },
    user_name(){
      return this.$store.getters.user_name;
    },
    user_surname(){
      return this.$store.getters.user_surname;
    },
    latitude(){
      return this.$store.getters.latitude;
    },
    longitude(){
      return this.$store.getters.longitude;
    }
  },
  watch: {
    selected_category(){
      if(this.selected_category){
        this.getDocketSubCategories(this.selected_category.id)
      }
    },
  },
  setup () {
    const noSleep = new NoSleep();
    noSleep.enable();
    let title = process.env.VUE_APP_NAME;
    console.log("Title is :"+title)
    return {
      leftDrawerOpen: ref(false)
    }
  },
  methods :{
    setupPage(){
      if( this.token ){
        this.getPanicCategory();
      }
    },
    logStuff(){
      console.log(this.panicReason)
    },
    getDocketSubCategories(category_id){
      this.selected_sub_category = null;
      axios.get(this.url + '/api/api-docket-sub-category', {
        headers: {
          Authorization: 'Bearer ' + this.token
        },
        params: {
          docket_category_id: category_id
        }
      }).then(response => {
        this.$store.commit('UPDATE_DOCKET_SUB_CATEGORIES', response.data);
      });
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users')
          .then((res) => {
            let user_id = JSON.parse( localStorage.getItem('user_id') );
            this.users = res.data.data;
            this.users.forEach((user) => {
              if(user.id===user_id){
                this.userName = user.name;
                localStorage.setItem( 'user_name', JSON.stringify(this.userName) );
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    closeReportModal(){
      this.displayReportModal = false;
      this.selected_sub_category = null;
      this.selected_category = null;
      this.description = '';
      this.casePhotos = [];
    },
    submitIssue(){
      this.displayReportModal = false;
      this.loading = true;
      let data = new FormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }

      data.append("user_id", this.user_id)
      data.append("docket_category_id" ,this.selected_category.id);
      if(this.selected_sub_category){
        data.append("docket_sub_category_id" ,this.selected_sub_category.id);
      }
      data.append('company_level_five_id',this.current_level_five.id);
      data.append('description',this.description);
      data.append("address" ,"None");
      data.append('longitude',this.longitude);
      data.append('latitude',this.latitude);
      let fetchPromises = this.casePhotos.map((casePhoto, index) => {
        return fetch(casePhoto)
            .then(res => res.blob())
            .then(blob => {
              console.log("Appending file " + blob + ' app_photo' + index + '.jpg');
              data.append("images[]", blob, 'app_photo' + index +  '.jpg');  // Ensure files are appended as an array
            });
      });

      Promise.all(fetchPromises).then(() => {
        axios.post(this.url + '/api/api-dockets', data, config)
            .then((res) => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: res.data.message
              });
              this.displayReportModal = false;
            })
            .catch(error => {
              console.error('There was an error!', error);
              Notify.create({
                color: 'red-4',
                textColor: 'white',
                icon: 'error',
                message: 'There was an error creating the docket.'
              });
            });
      }).catch(error => {
        console.error('Error fetching case photos!', error);
        Notify.create({
          color: 'red-4',
          textColor: 'white',
          icon: 'error',
          message: 'Error fetching case photos.'
        });
      });
      this.loading = false;
      this.resetForm();
    },
    submitPanic(item){
      this.checkAndGetLocation();
      this.displayPanicModal = false;
      this.loading = true;
      let data = new FormData();
      let config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      data.append("user_id", this.user_id)
      data.append("docket_category_id" ,this.panic_category[0].id);
      data.append("docket_sub_category_id" ,item.id);
      data.append('company_level_five_id',this.current_level_five.id);
      data.append('description',this.user_name+' '+this.user_surname+ ' Panic '+ item.description);
      data.append("address" ,"None");
      data.append('longitude',this.longitude);
      data.append('latitude',this.latitude);
      let fetchPromises = this.panicPhotos.map((casePhoto, index) => {
        return fetch(casePhoto)
            .then(res => res.blob())
            .then(blob => {
              console.log("Appending file " + blob + ' app_photo' + index + '.jpg');
              data.append("images[]", blob, 'app_photo' + index +  '.jpg');  // Ensure files are appended as an array
            });
      });
      Promise.all(fetchPromises).then(() => {
        axios.post(this.url + '/api/api-dockets', data, config)
            .then((res) => {
              Notify.create({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: res.data.message
              });
              this.displayReportModal = false;
            })
            .catch(error => {
              console.error('There was an error!', error);
              Notify.create({
                color: 'red-4',
                textColor: 'white',
                icon: 'error',
                message: 'There was an error creating the docket.'
              });
            });
      }).catch(error => {
        console.error('Error fetching case photos!', error);
        Notify.create({
          color: 'red-4',
          textColor: 'white',
          icon: 'error',
          message: 'Error fetching case photos.'
        });
      });
      this.loading = false;
    },
    resetForm(){
      this.selected_category = null;
      this.selected_sub_category = null;
      this.description = '';
      this.casePhotos = [];
    },
    checkAndGetLocation() {
      // Use navigator.permissions.query to check for geolocation permissions
      navigator.permissions.query({ name: 'geolocation' })
          .then((result) => {
            if (result.state === 'granted') {
              // Permissions are granted, get current position
              this.getCurrentPosition();
            } else if (result.state === 'prompt') {
              // Permissions are prompted, request permission and then get position
              navigator.geolocation.getCurrentPosition(
                  this.handlePositionSuccess,
                  this.handlePositionError,
                  { enableHighAccuracy: true }
              );
            } else if (result.state === 'denied') {
              alert('Location Not allowed');
              // Optionally, request permissions or show further instructions
            }
          })
          .catch((error) => {
            console.error('Error checking geolocation permissions:', error);
          });
    },

    getCurrentPosition() {
      // Get the current position using the Geolocation API
      navigator.geolocation.getCurrentPosition(
          this.handlePositionSuccess,
          this.handlePositionError,
          { enableHighAccuracy: true }
      );
    },

    handlePositionSuccess(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      localStorage.setItem('latitude', JSON.stringify(latitude));
      localStorage.setItem('longitude', JSON.stringify(longitude));

      this.$store.commit('UPDATE_LAT', latitude);
      this.$store.commit('UPDATE_LNG', longitude);

      console.log('Location updated successfully:', latitude, longitude);
    },

    handlePositionError(error) {
      console.error('Error getting location:', error);
      alert('Unable to retrieve location.');
    },
    displayPanic(){
      if(!this.current_level_five){
        Notify.create({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'Please select a '+this.five_description+' to continue'
        });
      }else{
        if(this.displayPanicModal === false){
          this.displayPanicModal = true;
        }
      }
    },
    closePanicModal(){
      this.displayPanicModal = false;
    },
    displayReport(){
      if(!this.current_level_five){
        Notify.create({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'Please select a '+this.five_description+' to continue'
        });
      }else{
        if(this.displayReportModal === false){
          this.displayReportModal = true;
        }
      }

    },
    getPanicCategory(){
      console.log("Getting panics");
      axios.get(this.$store.getters.url+'/api/api-panic-category',{
        headers :{
          Authorization : 'Bearer '+this.token
        }
      })
          .then((res) => {
            this.panic_category = res.data;
            if(!this.panic_category.length > 0){
              Notify.create({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'No Panic Category found'
              });
            }else{
              this.getPanicSubCategories();
            }
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
      });
    },
    getPanicSubCategories() {
      console.log("Getting panics");
      axios.get(this.$store.getters.url + '/api/api-panic-sub-category', {
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      }).then((res) => {
        if(res.data.length > 0){
          this.panic_sub_categories = res.data;
        }else{
          Notify.create({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'No Panic Sub Category found'
          });
        }
      })
          .catch((error) => {
            console.log(error);
          }).finally(() => {
      });
    },
    async takePhoto() {
      let case_type = "none";
      if(this.displayPanicModal === true){
        case_type = "panic";
        this.displayPanicModal = false;
      }
      if(this.displayReportModal === true){
        case_type = "report";
        this.displayReportModal = false;
      }
      const options = {
        resultType: CameraResultType.Uri
      };
      const originalPhoto = await Camera.getPhoto(options);
      if(case_type==="panic"){
        this.panicPhotos.push(originalPhoto.webPath);
        this.displayPanicModal = true;

      }
      if(case_type==="report"){
        this.casePhotos.push(originalPhoto.webPath);
        this.displayReportModal = true;
      }
    },
  }
}
</script>

<style scoped>
.circle {
  position: relative;
  width: 100px;
  height: 100px; /* Make it a circle */
  border-radius: 50%;
  background-color: rgb(135, 235, 140);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, darkred, red); /* Gradient for glossy effect */
}

/* Add a shine effect */
.circle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0) 70%);
  border-radius: 50%;
  opacity: 0.8;
}

.circle::after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
  opacity: 0.9;
  filter: blur(1px);
}
</style>



