<template>
  <q-page padding>
    <p>Logout</p>
  </q-page>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: 'PageLogout',
  mounted() {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    localStorage.removeItem('app_status');
    this.$store.commit('UPDATE_TOKEN',null);
    const router = useRouter();
    let token = JSON.parse(localStorage.getItem('token'));
    if (!token) {
      router.push({name: "login"})
    }
  }
}
</script>
