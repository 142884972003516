<template>
  <q-list bordered>
      <q-item>
        <q-item-section class="text-h5">Case: {{this.returned_cases.id}}</q-item-section>
        <q-item-section>
            People of interest
        </q-item-section>
          <q-item-section avatar>
            <a >
              <q-icon color="primary" name="settings"></q-icon>
            </a>
          </q-item-section>
      </q-item>

      <q-item>
            <q-btn round icon="link" @click="linkPerson()"></q-btn>
            <q-btn round icon="add" @click="addPerson()"></q-btn>
      </q-item>
      <q-item>
        <q-item-section>
          <q-item-label>
            People of interest
          </q-item-label>
        </q-item-section>
      </q-item>
    <q-item v-for="(person,index) in returned_cases.pois" :key="index">
      <q-list>
        <q-item>
          <q-item-section thumbnail>
            <q-item-label bordered>
              <p>Fullname: {{ person.first_name }} {{ person.last_name }}</p>
              <p>Type:
                  {{ person.poi_type.description }}
              </p>
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
              <q-btn round icon="edit" @click="editSomePerson(person)"></q-btn>
              <q-btn round icon="delete" @click="removePerson(person.id)"></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-item>
  </q-list>
  <q-dialog v-model="this.personLinkModalVisible" transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 80%">
      <q-card-section class="q-pa-md">
        <q-form>
          <label class="text-h5">
            Select People
          </label>
          <q-select
              ref="poi_select"
              v-model="this.selected_person"
              :options="this.people"
              use-input
              @filter="filterFn"
              use-chips
              multiple
              clearable
              :loading="this.loading"
              label="Search and Select"
              :option-label="option => option.first_name + ' ' + option.last_name"
          >
          </q-select>
        </q-form>
      </q-card-section>
      <q-card-actions class="q-pa-md">
        <q-btn submit @click="closeModal()" label="Cancel" color="secondary"></q-btn>
        <q-btn ref="submitPerson" submit @click="submitPerson()" label="Submit" color="primary"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="this.personAddModalVisible" transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pa-md">
        <q-form>
              <label>Person Name (*)</label>
              <q-input
                  v-model="name"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
              ></q-input>
              <label>Person Surname (*)</label>
              <q-input
                  v-model="surname"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
              ></q-input>
              <label>Person Type</label>
              <q-select
                  v-model="poi_type_id"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
                  :options="poi_types"
              >
              </q-select>
              <label>Citizenship</label>
              <q-select
                  v-model="country_code"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
                  :options="['ZA','Foreign']"
              >
              </q-select>
              <label v-if="country_code === 'ZA'">Person ID Nr</label>
              <q-input
                  v-if="country_code === 'ZA'"
                  v-model="id_number"
                  v-maska data-maska="#############"
              />
              <label v-if="country_code === 'Foreign'">Person Passport Nr</label>
              <q-input v-if="country_code === 'Foreign'" v-model="passport_number"></q-input>
              <label>Cellphone</label>
          <q-input
              v-model="this.cellphone"
              v-maska data-maska="+## ## ### ####"
              type="text"
              name="cellphone"
              id="cellphone"
              class="dark:bg-gray-700 dark:text-gray-100 block w-full rounded-md border-0 py-1.5 dark:text-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
              <label>Race</label>
              <q-select
                  v-model="poi_race_id"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
                  :option-value="option => option.value"
                  :options="this.races"
              >
              </q-select>
              <label>Gender</label>
              <q-select
                  v-model="poi_gender_id"
                  :rules="[val => !!val || 'Field is required']"
                  lazy-rules
                  :option-value="option => option.value"
                  :options="this.genders"
              >
              </q-select>


        </q-form>
      </q-card-section>
      <q-card-actions class="q-pa-md">
        <q-btn submit @click="closeAddModal()" label="Cancel" color="secondary"></q-btn>
        <q-btn submit @click="createPerson()" label="Submit" color="primary"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog v-model="this.personEditModalVisible" transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pa-md">
        <q-form>
          <label>Person Name (*)</label>
          <q-input
              v-model="editPerson.first_name"
              :rules="[val => !!val || 'Field is required']"
              lazy-rules
          ></q-input>

          <label>Person Surname (*)</label>
          <q-input
              v-model="editPerson.last_name"
              :rules="[val => !!val || 'Field is required']"
              lazy-rules
          ></q-input>

          <label>Person Type</label>
          <q-select
              v-model="editPerson.poi_type_id"
              :rules="[val => !!val || 'Field is required']"
              lazy-rules
              :option-value="'value'"
              :option-label="'label'"
              map-options
              :options="poi_types"
          ></q-select>

          <label>Country</label>
          <q-select
              v-model="editPerson.country_code"
              :rules="[val => !!val || 'Field is required']"
              lazy-rules
              :options="['ZA', 'Foreign']"
              emit-value
              label="Country"
          />
          <label v-if="editPerson.country_code === 'ZA'">Person ID Nr</label>
          <q-input
              v-if="editPerson.country_code === 'ZA'"
              v-maska data-maska="#############"
              v-model="editPerson.id_number"
          ></q-input>

          <label v-if="editPerson.country_code === 'Foreign'">Person Passport Nr</label>
          <q-input
              v-if="editPerson.country_code === 'Foreign'"
              v-model="editPerson.passport_number"
          ></q-input>

          <label>Cellphone</label>
          <q-input
              v-model="editPerson.cellphone"
              v-maska data-maska="+## ## ### ####"
              type="text"
              name="cellphone"
              id="cellphone"
              class="dark:bg-gray-700 dark:text-gray-100 block w-full rounded-md border-0 py-1.5 dark:text-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />

          <label>Race</label>
          <q-select
              v-model="editPerson.poi_race_id"
              :rules="[val => !!val || 'Field is required']"
              lazy-rules
              :option-value="'value'"
              :option-label="'label'"
              map-options
              :options="races"
          ></q-select>

          <label>Gender</label>
          <q-select
              v-model="editPerson.poi_gender_id"
              :rules="[val => !!val || 'Field is required']"
              lazy-rules
              :option-value="'value'"
              :option-label="'label'"
              map-options
              :options="genders"
          ></q-select>
        </q-form>
      </q-card-section>

      <q-card-actions class="q-pa-md">
        <q-btn @click="closeEditModal" label="Cancel" color="secondary"></q-btn>
        <q-btn @click="updatePerson" label="Update" color="primary"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script>
import {vMaska} from "maska/vue";
import axios from "axios";
import {Notify} from "quasar";
export default {
  name: 'CasePoi',
  directives:{
    maska : vMaska
  },
  props: [
      'returned_cases'
  ],
  data(){
      return {
        loading: false,
        name: null,
        surname: null,
        id_number: null,
        passport_number: null,
        country_code: 'ZA',
        poi_type_id: null,
        poi_types: [],
        genders: [],
        races: [],
        cellphone: null,
        poi_gender_id: null,
        poi_race_id: null,
        selected_person: null,
        people_involved: [],
        people: [],
        options: [],
        personLinkModalVisible: false,
        personAddModalVisible: false,
        personEditModalVisible: false,
        editPerson: {
          first_name: null,
          last_name: null,
          poi_type_id: null,
          country_code: 'ZA',
          id_number: null,
          passport_number: null,
          cellphone : null,
        }
      }
  },
  mounted(){
    this.getPersonTypes();
    this.getGenders();
    this.getRaces();
    this.getPeople();
  },
  emits: ['reload'],
  computed:{
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  methods:{
    onEnterPress() {
        this.$refs.selectRef.blur();
        this.$refs.submitPerson.$el.focus();
    },
    closeEditModal(){
      this.editPerson =  {
        first_name: null,
            last_name: null,
            poi_type_id: null,
            country_code: 'ZA',
            id_number: null,
            passport_number: null,
            cellphone : null,
      };
      this.personEditModalVisible = false;
    },
    editSomePerson(person){
      console.log(person);
      this.editPerson = Object.assign(this.editPerson, person);
      this.editPerson.poi_type_id = parseInt(person.poi_type_id, 10);
      this.editPerson.poi_race_id = parseInt(person.poi_race_id, 10);
      this.editPerson.poi_gender_id = parseInt(person.poi_gender_id, 10);
      if (person.contact_details && Array.isArray(person.contact_details)) {
        const cellphoneDetail = person.contact_details.find(
            (detail) => detail.contact_detail_type?.description === 'Cellphone'
        );
        // Assign the contact_detail to editPerson.cellphone if found
        this.editPerson.cellphone = cellphoneDetail ? cellphoneDetail.contact_detail : null;
      } else {
        // Set to null if no contact details exist
        this.editPerson.cellphone = null;
      }
      this.personEditModalVisible = true;
    },
    updatePerson(){
      if(this.editPerson.country_code === 'ZA'){
        this.editPerson.passport_number = null;
      }
      if(this.editPerson.country_code === 'Foreign'){
        this.editPerson.id_number = null;
      }
      const poiId = this.editPerson.id; // Replace with your actual POI ID variable
      const url = `${this.$store.getters.url}/api/api-update-pois/`;
      const formData = new FormData();
      formData.append('id', poiId);
      formData.append('first_name', this.editPerson.first_name);
      formData.append('last_name', this.editPerson.last_name);
      formData.append('poi_type_id', this.editPerson.poi_type_id);
      formData.append('country_code', this.editPerson.country_code);
      formData.append('id_number', this.editPerson.id_number);
      formData.append('passport_number', this.editPerson.passport_number);
      formData.append('cellphone', this.editPerson.cellphone);
      formData.append('poi_race_id', this.editPerson.poi_race_id);
      formData.append('poi_gender_id', this.editPerson.poi_gender_id);
        axios.post(url, formData, {
            headers: {
              'Authorization': 'Bearer '+this.$store.getters.token,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => {
            console.log('Response:', response.data);
            Notify.create({
              message: 'Person updated successfully',
              color: 'positive',
              textColor: 'white',
              timeout: 2000,
              position: 'center'
            });
          })
          .catch(error => {
            console.error(error);
          });
        this.$emit('reload');
        this.closeEditModal();
    },
    createPerson(){
      console.log(this.id_number);
      if(this.country_code === 'ZA'){
        this.passport_number = null;
      }
      if(this.country_code === 'Foreign'){
        this.id_number = null;
      }

      if(this.poi_type_id === null){
        Notify.create({
          message: 'Please select a person type',
          color: 'negative',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        return;
      }
      if(this.poi_gender_id === null){
        Notify.create({
          message: 'Please select a gender',
          color: 'negative',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        return;
      }
      axios.post(this.$store.getters.url+'/api/api-pois',{
        docket_id : this.returned_cases.id,
        first_name : this.name,
        country_code: this.country_code,
        poi_type_id : this.poi_type_id.value,
        last_name : this.surname,
        poi_race_id : this.poi_race_id.value,
        id_number : this.id_number,
        passport_number : this.passport_number,
        cellphone : this.cellphone,
        user_id : this.user_id,
        poi_gender_id: this.poi_gender_id.value
      }).then(() => {
        Notify.create({
          message: 'People added successfully',
          color: 'positive',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        this.selected_person = null;
        this.$emit('reload');
        this.closeAddModal();
      })
    },
    submitPerson(){
      axios.post(this.$store.getters.url+'/api/api-docket-poi-link',{
        docket_id : this.returned_cases.id,
        poi_id : this.selected_person,
        user_id : this.user_id
      }).then(() => {
        Notify.create({
          message: 'People added successfully',
          color: 'positive',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        this.selected_person = null;
        this.personLinkModalVisible = false;
        this.$emit('reload');
      })
    },
    removePerson(person){
      axios.post(this.$store.getters.url+'/api/api-docket-poi-unlink',{
        poi_id : person,
        docket_id : this.returned_cases.id,
      },{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+this.$store.getters.token,
        }
      }).then(() => {
        Notify.create({
          message: 'People removed successfully',
          color: 'positive',
          textColor: 'white',
          timeout: 2000,
          position: 'center'
        });
        this.selected_person = null;
        this.$emit('reload');
        this.personLinkModalVisible = false;
      })
    },
    getGenders(){
      axios.get(this.$store.getters.url+'/api/api-poi-gender')
          .then((res) => {
            res.data.data.forEach((type) => {
              this.genders.push({label: type.description, value: type.id});
            });
          });
    },
    getRaces(){
      axios.get(this.$store.getters.url+'/api/api-poi-race')
          .then((res) => {
            res.data.data.forEach((type) => {
              this.races.push({label: type.description, value: type.id});
            });
            console.table(this.races);
          });
    },
    getPersonTypes(){
      axios.get(this.$store.getters.url+'/api/api-poi-types')
          .then((res) => {
            res.data.forEach((type) => {
              this.poi_types.push({label: type.description, value: type.id});
            });
          })
    },
    abortFilterFn () {
    },
    async filterFn(val, update, abort) {
      if (val === '') {
        this.people = [];
        return;
      }
      this.loading = true;
      try {
        // Replace with your actual API endpoint
        this.getPeople(val);
        update();
      } catch (error) {
        console.error(error);
        abort();
      } finally {
        this.loading = false;
      }
    },
    getPeople(val){
      this.loading = true;
        axios.get(this.$store.getters.url+'/api/api-pois',{
          params: {
            search_term:val
          }
        })
        .then((res) => {
          this.people = res.data;
          this.loading = false;
        })
        .catch(() => {
        }).finally(() => {
        });
    },
    linkPerson(){
          this.personLinkModalVisible = true;
    },
    addPerson(){
          this.personAddModalVisible = true;
    },
    closeAddModal(){
        this.poi_gender_id = null;
        this.name = null;
        this.surname = null;
        this.cellphone = null;
        this.id_number = null;
        this.personAddModalVisible = false;
    },
    closeModal(){
      this.personLinkModalVisible = false;
    }
  }
}
</script>
