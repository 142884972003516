<template>
  <div class="hello">
    <q-card class="my-card">
      <q-card-section>
        <q-item-label class="text-h3">
        </q-item-label>
        <q-item-label>
        </q-item-label>
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section class="bg-white">
        <q-img
            :src="logo_url"
            :height="'300px'"
            :fit="'contain'"
            style="object-fit: contain;"
        />
      </q-card-section>
    </q-card>
    <q-card class="my-card">
      <q-card-section>
        <q-list bordered>
          <q-item>
            You are at: {{this.latitude}} ; {{this.longitude}}
          </q-item>
          <q-item>
            <label>Choose {{five_description}}:</label>
          </q-item>
          <q-item>
            <q-select
                filled
                v-model="this.selected_level_five"
                emit-value
                :options="this.level_fives"
                :label="this.five_description"
                option-label="name"
                class="full-width"
                @blur="setLevelFive"
            ></q-select>
          </q-item>
          <q-item v-if="five_description">
            <q-btn @click="setLevelFive">
              Update
            </q-btn>
            <q-btn @click="loadStores()">
              Load {{ five_description }}
            </q-btn>
          </q-item>
        </q-list>
      </q-card-section>

    </q-card>
    <q-card>
      <q-card-section>
        <q-list bordered>
          <div  v-for="(conversation,index) in this.conversations" :key=index :index="this.draw_list" >
            <q-item >
              <q-item-section class="text-h5" :id="index">
                {{conversation.name}}
              </q-item-section>
              <q-form method="delete"  @submit="this.deleteConversation(conversation.id)"
                      class="q-gutter-md">
                <q-item-section avatar>
                  <q-btn clickable v-ripple type="submit" color="red" icon="delete" label="Delete"></q-btn>
                </q-item-section>
              </q-form>
            </q-item>
            <q-item>
              <q-item-section>
                {{conversation.message}}
              </q-item-section>
            </q-item>
          </div>
        </q-list>
      </q-card-section>

    </q-card>
  </div>
</template>

<script>
import axios from "axios";
import { Notify } from 'quasar'
import NoSleep from 'nosleep.js';
import {Geolocation} from "@capacitor/geolocation";

export default {
  name: 'PageHome',
  computed: {
    url: function(){
      return this.$store.getters.url;
    },
    user_id : function(){
      return JSON.parse( this.$store.getters.user_id );
    },
    level_ones : function(){
      return this.$store.getters.level_ones;
    },
    one_description: function(){
      return this.$store.getters.one_description;
    },
    current_level_one: function(){
      let one = this.$store.getters.current_level_one;
      return one;
    },
    latitude(){
      return this.$store.getters.latitude;
    },
    longitude(){
      return this.$store.getters.longitude;
    },
    level_twos : function(){
      return this.$store.getters.level_twos;
    },
    two_description: function(){
      return this.$store.getters.two_description;
    },
    current_level_two: function(){
      let two = this.$store.getters.current_level_two;
      return two;
    },
    level_threes : function(){
      return this.$store.getters.level_threes;
    },
    three_description: function(){
      return this.$store.getters.three_description;
    },
    current_level_three: function(){
      let three = this.$store.getters.current_level_three;
      return three;
    },
    level_fours : function(){
      return this.$store.getters.level_fours;
    },
    four_description: function(){
      return this.$store.getters.four_description;
    },
    current_level_four: function(){
      let four = this.$store.getters.current_level_four;
      return four;
    },
    level_fives : function(){
      return this.$store.getters.level_fives;
    },
    current_level_five: function(){
      let five = this.$store.getters.current_level_five;
      return five;
    },
    five_description: function(){
      return this.$store.getters.five_description;
    },
    token: function () {
      return this.$store.getters.token;
    },
  },
  setup(){
    const noSleep = new NoSleep();
    noSleep.enable();
  },
  data(){
    let logo_url = 'https://superadmin.blackwidow.org.za/customer_logo/specific';
    let app_name = process.env.VUE_APP_NAME;
    return {
      selected_level_one : null,
      selected_level_two : null,
      selected_level_three : null,
      selected_level_four : null,
      selected_level_five : null,
      conversations : Object,
      draw_list : 0,
      logo_url,
      app_name
    }
  },
  watch: {
    current_level_one: function(){
      this.selected_level_one = this.current_level_one;
    },
    current_level_two: function(){
      this.selected_level_two = this.current_level_two;
    },
    current_level_three: function(){
      this.selected_level_three = this.current_level_three;
    },
    current_level_four: function(){
      this.selected_level_four = this.current_level_four;
    },
    current_level_five: function(){
      this.selected_level_five = this.current_level_five;
    }
  },
  mounted(){
    this.setupPage();
    this.getConversations();
    this.getLocation();
  },
  methods: {
    setupPage(){
      if(this.token){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        this.selected_level_one = this.current_level_one;
        this.selected_level_two = this.current_level_two;
        this.selected_level_three = this.current_level_three;
        this.selected_level_four = this.current_level_four;
        this.selected_level_five = this.current_level_five;
      }
       this.loadStores();
    },
    setLevelOne(){
      localStorage.setItem('level_one',JSON.stringify(this.level_one));
      this.$store.commit('UPDATE_CURRENT_LEVEL_ONE', this.selected_level_one);
      Notify.create({
        color: 'green-5',
        textColor: 'white',
        icon: 'check',
        message: this.one_description +' Changed successfully!',
        timeout: 10000,
      })
      window.location.reload();
    },
    setLevelTwo(){
      localStorage.setItem('level_two',JSON.stringify(this.level_two));
      this.$store.commit('UPDATE_CURRENT_LEVEL_TWO', this.selected_level_two);
      Notify.create({
        color: 'green-5',
        textColor: 'white',
        icon: 'check',
        message: this.two_description +' Changed successfully!',
        timeout: 10000,
      })
      window.location.reload();
    },
    setLevelThree(){
      this.$store.commit('UPDATE_CURRENT_LEVEL_THREE', this.selected_level_three);
      Notify.create({
        color: 'green-5',
        textColor: 'white',
        icon: 'check',
        message: this.three_description +' Changed successfully!',
        timeout: 10000,
      })
      window.location.reload();
    },
    setLevelFour(){
      this.$store.commit('UPDATE_CURRENT_LEVEL_FOUR', this.selected_level_four);
      Notify.create({
        color: 'green-5',
        textColor: 'white',
        icon: 'check',
        message: this.four_description +' Changed successfully!',
        timeout: 10000,
      })
      window.location.reload();
    },
    loadStores(){
      this.initializeAudio();
      this.$store.dispatch('getLevels');
    },
    initializeAudio(){
      const audio = new Audio('sos.mp3'); // Path to a silent or very short sound
      audio.play().catch((error) => console.log("Initial play blocked:", error));
    },
    setLevelFive(){
      this.$store.commit('UPDATE_CURRENT_LEVEL_FIVE', this.selected_level_five);
      if(this.selected_level_five.company_level_four){
        this.$store.commit('UPDATE_CURRENT_LEVEL_FOUR', this.selected_level_five.company_level_four);
        if(this.selected_level_five.company_level_four.company_level_three){
          this.$store.commit('UPDATE_CURRENT_LEVEL_THREE', this.selected_level_five.company_level_four.company_level_three);
          if(this.selected_level_five.company_level_four.company_level_three.company_level_two){
            this.$store.commit('UPDATE_CURRENT_LEVEL_TWO', this.selected_level_five.company_level_four.company_level_three.company_level_two);
            if(this.selected_level_five.company_level_four.company_level_three.company_level_two.company_level_one){
              this.$store.commit('UPDATE_CURRENT_LEVEL_ONE', this.selected_level_five.company_level_four.company_level_three.company_level_two.company_level_one);
            }
          }
        }
      }

      this.$store.dispatch('getDocketCategories');
      Notify.create({
        color: 'green-5',
        textColor: 'white',
        icon: 'check',
        message: this.five_description +' Changed successfully!',
        timeout: 10000,
      })
      window.location.reload();
    },
    getConversations(){
      // axios.get(this.$store.getters.url+'/api/cases/conversations?user_id='+this.user_id)
      //     .then((res) => {
      //       this.conversations = res.data.data;
      //       this.draw_list += 1;
      //     })
      //     .catch(() => {
      //
      //     }).finally(() => {
      //
      // });
    },
    getLocation(){
      if (Geolocation.checkPermissions()) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).then(newPosition => {
          localStorage.setItem('latitude', JSON.stringify(newPosition.coords.latitude));
          this.latitude = newPosition.coords.latitude;
          localStorage.setItem('longitude', JSON.stringify(newPosition.coords.longitude));
          this.longitude = newPosition.coords.longitude;
        })
      }
    },
    deleteConversation(index){
      let url = this.$store.getters.url+'/api/cases/conversations/'+index;
      axios.delete(url)
          .then(() => {
            this.getConversations();
          })
          .catch(() => {
          }).finally(() => {
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

