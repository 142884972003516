<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">

      <div :key="render_table">
        <div v-if="this.returned_tasks" class="text-h5 q-mb-md">You have {{filtered_tasks.length}} {{this.task_description}} assigned to you</div>
        <div v-if="this.returned_tasks" class="q-mb-md">
          <div class="q-m-md">
            <label>Search</label>
            <q-input class="q-card--bordered q-m-md q-pl-md" v-model="searchTerm" placeholder="" />
          </div>
          <div class="q-mb-lg">
            <label>Filter</label>
            <q-select v-model="this.selectedTaskCategory"
                      :options="this.task_categories"
                      emit-value
                      :label="this.task_description+' Category'"
                      option-label="description"
            />
          </div>

          <q-checkbox
              left-label
              v-model="includeClosed"
              label="Include Closed or Completed"
              :true-value="true"
              :false-value="false"
              checked-icon="task_alt"
              unchecked-icon="highlight_off"
          ></q-checkbox>
          <div>
            <q-btn type="button" @click="resetFilters()" class="text-sm q-mb-md">Reset Filters</q-btn>
          </div>
        </div>
        <div v-for="(task,index) in filtered_tasks" :key="index">
          <q-list bordered>
            <q-item :href="'/execute-task/'+task.id" clickable v-ripple>
              <q-item-section class="text-h5" :id="index">{{task.id}} - {{task.title}}</q-item-section>
              <q-item-section avatar>
                <q-icon color="primary" name="settings"></q-icon>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>Task address: {{task.address}}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section :id="index">Due date: {{task.due_date}}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section>Status: {{ task.task_status.description }}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section>{{ task.description }}</q-item-section>
            </q-item>
            <q-item v-if="task.task_category">
              <q-item-section>Category: {{ task.task_category.description }}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section class="text-sm">
                {{this.five_description}} : {{task.company_level_five.name}}
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import {useQuasar} from "quasar";

export default {
  name: 'OpenTasksPage',
  setup(){
    return {
    }
  },
  data(){
    return {
      selectedTaskCategory : null,
      searchTerm : '',
      includeClosed : false,
      returned_tasks : null,
      render_table : null
    }
  },
  watch:{
    filtered_tasks(){
      this.filtered_tasks.forEach((task) => {
        console.log(task.task_status);
      });
    }
  },
  mounted(){
    this.syncData();
  },
  computed: {
    task_categories(){
      console.log(this.$store.getters.task_categories);
      return this.$store.getters.task_categories;
    },
    five_description(){
      return this.$store.getters.five_description;
    },
    task_description(){
      return this.$store.getters.task_description;
    },
    filtered_tasks() {
      if (this.returned_tasks) {
        return this.returned_tasks.filter((returned_task) => {
          // Matches search term
          console.log(returned_task.company_level_five)
          const matchesSearchTerm = (
              (returned_task.description && returned_task.description.toLowerCase().includes(this.searchTerm)) ||
              (returned_task.title && returned_task.title.toLowerCase().includes(this.searchTerm)) ||
              (returned_task.company_level_five && returned_task.company_level_five.name.toLowerCase().includes(this.searchTerm)) ||
              (returned_task.reference && returned_task.reference.toLowerCase().includes(this.searchTerm))
          );

          // Matches category
          const matchesCategory = this.selectedTaskCategory
              ? returned_task.task_category?.id === this.selectedTaskCategory.id
              : true;

          // Matches status
          const matchesStatus = !returned_task.task_status?.description?.toLowerCase().includes('losed') &&
              !returned_task.task_status?.description?.toLowerCase().includes('ompleted');

          // Apply filtering based on includeClosed
          if (this.includeClosed) {
            return matchesSearchTerm && matchesCategory;
          } else {
            return matchesSearchTerm && matchesCategory && matchesStatus;
          }
        });
      } else {
        return [];
      }
    },
    token: function () {
      return this.$store.getters.token;
    },
    url: function(){
      return this.$store.getters.url;
    },
    user_id : function(){
      return JSON.parse( this.$store.getters.user_id );
    }
  },
  methods : {
    resetFilters(){
      this.searchTerm = '';
      this.selectedTaskCategory = null;
      this.includeClosed = false;
    },
    async syncData(){
      const $q = useQuasar();
        axios.get(this.$store.getters.url+'/api/api-tasks', {
          headers: {
            'Authorization': 'Bearer ' + this.token
          },
          params: {
            owner_type: 'ManagedByMe',
          }
        })
          .then((res) => {
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Retrieved !! '
            })
            this.returned_tasks = res.data.data;
            this.render_table = true;
            console.log(this.returned_tasks);
          })
          .catch((error) => {
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: '+error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}
</script>
